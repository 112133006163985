import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _debounce from 'lodash-es/debounce';

import T from 'i18n-react';

import { CloseIcon, Content } from '@motorway/motorway-storybook-cra';

import { GA_TAGS, getCategoryForSticky, STICKY_VRM_EVENTS } from 'Utilities/analytics';

import Texts from '../../../texts.json';
import { safeSessionStorage } from '../../../utilities/storages';
import VrmChecker from '../../vrm/VrmChecker/VrmChecker';
import { useBreakpoint } from '../Breakpoints/Breakpoints';

import styles from './StickyVRM.scss';

T.setTexts(Texts);

const CLOSED_STICKY_VRM_KEY = 'disableStickyVRM';

const StickyVRM = ({
  $content,
  analyticsEvents,
  desktopCTA,
  disabled,
  header,
  mobileCTA,
  mobileOnly,
  onSubmit,
}) => {
  const stickyDimensions = useRef();
  const resizeObserver = useRef();
  const [stickyState, setStickyState] = useState({});
  const [closed, setClosed] = useState(safeSessionStorage.getItem(CLOSED_STICKY_VRM_KEY, false));

  const breakpoints = useBreakpoint();
  const tabletBreakpoint = breakpoints.maxWidth.breakpointTablet;

  const calculateDimensions = useRef(() => {
    requestAnimationFrame(() => { // https://github.com/airbnb/visx/pull/335
      const dimensions = $content.getBoundingClientRect();

      stickyDimensions.current = Object.assign(dimensions, {
        topAbsolute: window.scrollY + dimensions.top,
      });
    });
  });

  const { pathname } = useLocation();
  const category = getCategoryForSticky(pathname);

  useEffect(() => {
    const show = stickyState?.showStickyVRM;
    if (show) {
      STICKY_VRM_EVENTS.STICKY_VRM_BANNER_LOADED({
        category,
        label: 'old',
      });
    }
  }, [stickyState?.showStickyVRM, category, pathname]);

  useEffect(() => {
    const forceHide = (mobileOnly && !tabletBreakpoint);

    if (!forceHide) {
      calculateDimensions.current();
    }

    setStickyState((state) => ({
      ...state,
      forceHide,
    }));
  }, [tabletBreakpoint, mobileOnly]);

  useEffect(() => {
    const scrollEvent = () => {
      if (!stickyDimensions.current) {
        return;
      } // https://app.clubhouse.io/motorway/story/20032/typeerror-cannot-read-property-topabsolute-of-undefined

      const y = window.scrollY;
      const { height, topAbsolute } = stickyDimensions.current;

      const visible = (y > topAbsolute && y < (topAbsolute + height - window.innerHeight));

      setStickyState((state) => {
        if (state.forceHide) {
          return state;
        }

        if (state.showStickyVRM !== visible) {
          return {
            ...state,
            // Prevent FOUC
            showStickyVRM: visible,
            stickyVRMReady: true,
          };
        }

        // Returning state will prevent re-renders
        // https://reactjs.org/docs/hooks-reference.html#bailing-out-of-a-state-update
        return state;
      });
    };

    window.addEventListener('scroll', scrollEvent, {
      passive: true,
    });

    if ('ResizeObserver' in window) {
      // Allow blog images etc to load in and window resize
      // https://github.com/pelotoncycle/resize-observer/issues/23
      const resized = _debounce(calculateDimensions.current, 500);

      const resizeOb = new ResizeObserver(resized);

      resizeOb.observe($content);
      resizeObserver.current = resizeOb;
    }

    return () => {
      window.removeEventListener('scroll', scrollEvent, {
        passive: true,
      });

      resizeObserver?.current?.unobserve($content);
    };
  }, [$content]);

  if (!$content) {
    return (null);
  }

  const onBlur = () => setStickyState((state) => ({
    ...state,
    focusLock: false,
  }));

  const onFocus = () => {
    setStickyState((state) => ({
      ...state,
      focusLock: true,
    }));

    GA_TAGS.STICKY_VRM_INPUT_FOCUSED();
  };

  const onCloseClick = () => {
    setClosed(true);

    resizeObserver?.current?.unobserve($content);

    safeSessionStorage.setItem(CLOSED_STICKY_VRM_KEY, true);
  };

  const handleVrmSubmit = onSubmit ? (...props) => {
    onSubmit(...props);
    onBlur();
  } : undefined;

  const {
    focusLock,
    forceHide,
    showStickyVRM,
    stickyVRMReady,
  } = stickyState;

  const isClosed = closed && !tabletBreakpoint && !mobileOnly;
  const isShown = !forceHide && showStickyVRM && !isClosed;
  const toShow = focusLock || isShown;

  const wrapper = {
    className: cx(styles.stickyVRM, {
      [styles.show]: toShow,
      [styles.ready]: stickyVRMReady,
    }),
  };

  const buttonProps = {
    labelChildren: (
      <>
        <span className={styles.mobileCTA}>{mobileCTA}</span>
        <span className={styles.desktopCTA}>{desktopCTA}</span>
      </>
    ),
  };

  const events = {
    ...analyticsEvents,
    onButtonClick: (e) => {
      analyticsEvents?.onButtonClick?.(e);
    },
  };

  return (
    <Content className={styles.stickyVRMContent} {...{ onBlur, onFocus, wrapper }}>
      <CloseIcon className={styles.close} onClick={onCloseClick} />
      <Link
        onClick={() => STICKY_VRM_EVENTS.STICKY_VRM_BANNER_LOGO_CLICK({
          category,
          label: 'old',
        })}
        to="/"
      >
        <div className={styles.stickyLogo} />
      </Link>
      <div className={styles.stickyStrap}>{header}</div>
      <VrmChecker
        {...{ analyticsEvents: events, buttonProps, handleVrmSubmit }}
        chevron
        className={styles.stickyForm}
        disabled={disabled || !toShow}
        e2eName="sticky vrm checker"
        id='sticky'
        large={false}
        snowplowEvents={ true }
      />
    </Content>
  );
};

StickyVRM.defaultProps = {
  $content: { current: null },
  analyticsEvents: undefined,
  desktopCTA: T.translate('stickyVRMView.desktopCTA'),
  disabled: false,
  header: T.translate('stickyVRMView.strap'),
  mobileCTA: T.translate('stickyVRMView.mobileCTA'),
  mobileOnly: false,
  onSubmit: undefined,
};

StickyVRM.propTypes = {
  $content: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  analyticsEvents: PropTypes.object,
  desktopCTA: PropTypes.node,
  disabled: PropTypes.bool,
  header: PropTypes.node,
  mobileCTA: PropTypes.node,
  mobileOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default StickyVRM;
