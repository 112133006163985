import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Content } from '@motorway/motorway-storybook-cra';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { HERO_ANIMATION_STATES } from 'Utilities/animation';
import { usePrevious } from 'Utilities/hooks';

import { ComponentsWrapper } from 'Layout';

import { useBreakpoint } from '../../../../../components/misc/Breakpoints/Breakpoints';
import VehicleImage from '../../../../../components/misc/VehicleImage/VehicleImage';
import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import ChevronAnimation from '../../chevronAnimation/ChevronAnimation';
import FastlaneVRM from '../../homeVRM/HomeVRM';

import styles from './Hero.scss';

const MILEAGE_PATH = '/mileage';

const Hero = ({ analyticsEvents, isHideBreadcrumb = false, onLoad, subTitle, title }) => {
  const container = useRef();
  const history = useHistory();
  const breakpoints = useBreakpoint();
  const { breakpointMobile } = breakpoints.maxWidth;
  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);
  const [minHeight, setMinHeight] = useState('auto');
  const [loading, setLoading] = useState(HERO_ANIMATION_STATES.none);
  const [animationInProgress, setAnimationInProgress] = useState(false);
  const prevAnimationInProgress = usePrevious(animationInProgress);
  const prevPathname = usePrevious(history.location?.pathname);
  const [carStopped, setCarStopped] = useState(false);
  const [moveCars, setMoveCars] = useState(false);
  const [moveBack, setMoveBack] = useState(false);
  const [cssVars, setCssVars] = useState({});
  const isTransition = loading === HERO_ANIMATION_STATES.mileage;
  const loadingHandler = useCallback((val) => setLoading(val), []);

  useEffect(() => onLoad?.(), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (breakpointMobile && !history.location.pathname.endsWith(MILEAGE_PATH)
      && prevPathname?.endsWith(MILEAGE_PATH)) {
      setMoveBack(true);
      setCarStopped(false);
      setMoveCars(false);
    }
  }, [history.location, prevPathname, breakpointMobile]);

  useEffect(() => {
    if (loading && breakpointMobile) {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });
    }
  }, [loading, breakpointMobile]);

  useEffect(() => {
    if (isTransition && prevAnimationInProgress && !animationInProgress) {
      history.push(`/${vehicle.vrm}`, { animate: true });
    }
  }, [isTransition, animationInProgress, vehicle?.vrm, prevAnimationInProgress, history]);

  useEffect(() => {
    if (isTransition) {
      setCssVars({
        '--lock-opacity-delay': '0.5s',
        '--vrm-input-opacity-delay': '0.5s',
      });
    }
    if (loading) {
      setMinHeight(`${container?.current?.getBoundingClientRect().height || 0}px`);
      setAnimationInProgress(true);
      setMoveCars(true);
    }
  }, [loading, isTransition]);

  /**
   * IMPORTANT
   * If altering the height of `.contentWrapper` ensure you alter the min-heights to
   * prevent Google Pagespeed from penalising us for Cumlative Layout Shift
   */

  return (
    <div className={cx(styles.visibleWrapper, { [styles.transition]: isTransition })}>
      { isTransition && <div className={styles.cornerHelper} /> }
      <ComponentsWrapper className={styles.contentWrapper}>
        <Content className={styles.topNav}>
          { !isHideBreadcrumb && <Breadcrumbs onYellow /> }
        </Content>
        <ChevronAnimation
          loading={animationInProgress}
          {...{ isTransition, setAnimationInProgress }}
        />
        <div
          className={cx(styles.component, {
            [styles.moveBack]: moveBack,
          })}
          style={{ '--hero-min-height': minHeight }}
        >
          <div ref={container} style={cssVars}>
            <FastlaneVRM
              {...{ analyticsEvents, loadingHandler, subTitle, title }}
              inProgress={animationInProgress || Boolean(loading)}
            />
          </div>
        </div>
      </ComponentsWrapper>
      <VehicleImage
        className={cx(styles.vehicle, {
          [styles.start]: moveCars,
          [styles.stopped]: carStopped,
          [styles.transition]: isTransition,
          [styles.moveBack]: moveBack,
        })}
        name="silver"
        vehicleProps={{
          onTransitionEnd: () => {
            if (moveBack) {
              setMoveBack(false);
            } else {
              setCarStopped(true);
            }
          },
        }}
      />
      <VehicleImage
        className={cx(styles.vehicleBlack, {
          [styles.start]: moveCars,
          [styles.stopped]: carStopped,
          [styles.transition]: isTransition,
          [styles.moveBack]: moveBack,
        })}
        name="grey"
      />
    </div>
  );
};

Hero.defaultProps = {
  analyticsEvents: {},
  isHideBreadcrumb: false,
  onLoad: () => {},
  subTitle: undefined,
  title: undefined,
};

Hero.propTypes = {
  analyticsEvents: PropTypes.shape(),
  isHideBreadcrumb: PropTypes.bool,
  onLoad: PropTypes.func,
  subTitle: PropTypes.node,
  title: PropTypes.node,
};

export default Hero;
