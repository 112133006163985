export const FLAG = {
  CSMC_OFFER_SELECT_CTA_LABEL: 'csmc_offer_select_cta_label',
  SKIP_EXPIRED_ENQUIRY: 'tc_skip_expired_enquiry',
  SOCIAL_VALIDATION_ON_AUTH: 'tc_social_validation_on_auth',
} as const;

export const EVENT = {
  ENQUIRY_CREATED: 'enquiry_created',
  OFFER_SELECT: 'offer_select',
  VIEW_OWNERSHIP_FINANCE_QUESTION: 'view_ownership_finance_question',
  VIEW_PHOTOS_SECTION: 'view_photos_section',
} as const;

export const EXPERIMENT_DECISIONS_HEADER = 'experiment-decisions';
